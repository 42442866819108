import apiClient from "@/services/ApiClient";

const name = "dashboard";

export default {
  name,
  getDailyTotals() {
    return apiClient.get("/calls/daily-totals");
  },
  getWeekCallStats(params) {
    return apiClient.get(`/stats/closed-calls-user?${params}`);
  },
};
