import brandsService from "@/modules/decoralife-tele-mkt/services/brandsService";
import callsHistoryService from "@/modules/decoralife-tele-mkt/services/callsHistoryService";
import callsPlannedService from "@/modules/decoralife-tele-mkt/services/callsPlannedService";
import callResultsService from "@/modules/decoralife-tele-mkt/services/callResultsService";
import clientsService from "@/modules/decoralife-tele-mkt/services/clientsService";
import clientsBrandsService from "@/modules/decoralife-tele-mkt/services/clientsBrandsService";
import newClientsService from "@/modules/decoralife-tele-mkt/services/newClientsService";
import TeleMktUsersService from "@/modules/decoralife-tele-mkt/services/teleMktUsersService";
import prioritiesService from "@/modules/decoralife-tele-mkt/services/prioritiesService";
import qualificationsService from "@/modules/decoralife-tele-mkt/services/qualificationsService";
import statsService from "@/modules/decoralife-tele-mkt/services/statsService";
import dirTeleMktUsersService from "@/modules/decoralife-tele-mkt/services/dirTeleMktUsersService";

export default [
  brandsService,
  callsHistoryService,
  callResultsService,
  callsPlannedService,
  clientsService,
  clientsBrandsService,
  dirTeleMktUsersService,
  newClientsService,
  prioritiesService,
  TeleMktUsersService,
  qualificationsService,
  statsService,
];
