import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  {
    path: "provider-orders",
    redirect: { name: "eecsProviderOrders" },
  },
  {
    path: "provider-orders/list",
    name: "eecsProviderOrders",
    component: () => import("@/modules/decoralife-eecs-admin/views/ProviderOrdersCrud"),
    meta: {
      auth: [3],
      title: "Pedidos de proveedor",
      serviceName: "provider-orders",
    },
  },

  {
    path: "provider-orders/list/:id",
    name: "eecsProviderOrdersEdit",
    component: () =>
      import("@/modules/decoralife-eecs-admin/views/ProviderOrdersProviderOrderLinesCrud"),
    meta: {
      auth: [3],
      serviceName: "provider-order-lines",
      title: "Detalle de pedido de proveedor",
    },
  },

  {
    path: "provider-orders/provider-order-line/:id",
    name: "eecsProviderOrdersEditEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "provider-order-lines",
      title: "Detalle de línea de pedido de proveedor",
    },
  },

  {
    path: "provider-orders/shipments",
    name: "eecsProviderShipments",
    component: () => import("@/modules/decoralife-eecs-admin/views/ProviderShipments"),
    meta: {
      auth: [3],
      title: "Envíos de proveedor",
      serviceName: "shipments",
    },
  },
  {
    path: "provider-orders/shipments/:id",
    name: "eecsProviderShipmentsEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "shipments",
      title: "Detalle de envío de proveedor",
    },
  },
];
