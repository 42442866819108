import authService from "@/modules/decoralife-eecs-provider/services/AuthService";
import ExtranetProviderDashboard from "@/modules/decoralife-eecs-provider/services/ExtranetProviderDashboard";
import ExtranetProviderOrders from "@/modules/decoralife-eecs-provider/services/ExtranetProviderOrders";
import ExtranetProviderOrdersPostponed from "@/modules/decoralife-eecs-provider/services/ExtranetProviderOrdersPostponed";
import ExtranetProviderOrdersPending from "@/modules/decoralife-eecs-provider/services/ExtranetProviderOrdersPending";
import ExtranetProviderOrdersPendingPostponed from "@/modules/decoralife-eecs-provider/services/ExtranetProviderOrdersPendingPostponed";
import ExtranetProviderOrdersUpdate from "@/modules/decoralife-eecs-provider/services/ExtranetProviderOrderLines";
import ExtranetProviderShipments from "@/modules/decoralife-eecs-provider/services/ExtranetProviderShipments";

const services = [
  authService,
  ExtranetProviderDashboard,
  ExtranetProviderOrders,
  ExtranetProviderOrdersPending,
  ExtranetProviderOrdersPostponed,
  ExtranetProviderOrdersPendingPostponed,
  ExtranetProviderOrdersUpdate,
  ExtranetProviderShipments,
];

export default {
  get: name => services.find(s => s.name === name),
};
