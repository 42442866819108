import Intranet from "@/layouts/intranet-tabs";

import eecsPosRoutes from "@/modules/decoralife-eecs-admin/router/eecsPosRoutes";
import eecsProviderOrdersRoutes from "@/modules/decoralife-eecs-admin/router/eecsProviderOrdersRoutes";
import eecsProductsRoutes from "@/modules/decoralife-eecs-admin/router/eecsProductsRoutes";
import eecsProvidersRoutes from "@/modules/decoralife-eecs-admin/router/eecsProvidersRoutes";
import eecsSettingsRoutes from "@/modules/decoralife-eecs-admin/router/eecsSettingsRoutes";
import eecsUserRoutes from "@/modules/decoralife-eecs-admin/router/eecsUserRoutes";

export default [
  {
    path: "/eecs",
    component: Intranet,
    redirect: { name: "eecsPos" },
    children: [
      ...eecsPosRoutes,
      ...eecsProviderOrdersRoutes,
      ...eecsProductsRoutes,
      ...eecsProvidersRoutes,
      ...eecsSettingsRoutes,
      ...eecsUserRoutes,
    ],
  },
];
