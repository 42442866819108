import { crudMdActions } from "@/services/crud-md-actions";
import apiClient from "@/services/ApiClient";

const name = "pos-pending-order";

export default {
  name,
  ...crudMdActions(name),
  downloadNewPos: payload => apiClient.post("/pos/import", payload),
};
