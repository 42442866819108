import Intranet from "@/modules/decoralife-eecs-provider/layouts/intranet";
import crudUpdateMd from "@/modules/decoralife-eecs-provider/components/crud-md/actions/Edit";

const orderEditView = (parentPath, parentName) => {
  return [
    {
      path: parentPath + "/:id",
      name: parentName + "Edit",
      component: () =>
        import(
          "@/modules/decoralife-eecs-provider/views/ExtranetProviderOrdersProviderOrderLinesCrud"
        ),
      meta: {
        auth: [5],
        serviceName: "extranet-provider-order-lines",
        title: "Detalle de pedido",
      },
    },

    {
      path: parentPath + "/order-line/:id",
      name: parentName + "Edit" + "Edit",
      component: crudUpdateMd,
      meta: {
        auth: [5],
        serviceName: "extranet-provider-order-lines",
        title: "Línea de pedido",
      },
    },
  ];
};

export default [
  {
    path: "/login",
    component: () => import("@/modules/decoralife-eecs-provider/layouts/intranet-empty"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("@/modules/decoralife-eecs-provider/views/UserLogin.vue"),
        meta: {
          auth: "guest",
          title: "Decoralife | Acceso a la extranet EECS",
        },
      },
    ],
  },

  {
    path: "/",
    component: Intranet,
    redirect: { name: "ExtranetProviderDashboard" },
    children: [
      {
        path: "dashboard",
        component: () =>
          import("@/modules/decoralife-eecs-provider/views/ExtranetProviderDashboard"),
        name: "ExtranetProviderDashboard",
        meta: {
          auth: [5],
          title: "Panel de control EECS",
          hideBreadcrumb: true,
          serviceName: "extranet-provider-dashboard",
        },
      },

      {
        path: "orders/pending",
        name: "ExtranetProviderOrdersPending",
        component: () =>
          import("@/modules/decoralife-eecs-provider/views/ExtranetProviderOrdersPending"),
        meta: {
          auth: [5],
          serviceName: "extranet-provider-orders-pending",
          serviceCustomPath: "/extranet-providers/provider-orders-pending",
          title: "Pedidos pendientes de confirmar",
        },
      },
      ...orderEditView("orders/pending", "ExtranetProviderOrdersPending"),

      {
        path: "orders/confirmed",
        name: "ExtranetProviderOrders",
        component: () => import("@/modules/decoralife-eecs-provider/views/ExtranetProviderOrders"),
        meta: {
          auth: [5],
          serviceName: "extranet-provider-orders",
          title: "Pedidos confirmados",
        },
      },
      ...orderEditView("orders/confirmed", "ExtranetProviderOrders"),

      {
        path: "orders-postponed/pending",
        name: "ExtranetProviderOrdersPendingPostponed",
        component: () =>
          import("@/modules/decoralife-eecs-provider/views/ExtranetProviderOrdersPendingPostponed"),
        meta: {
          auth: [5],
          serviceName: "extranet-provider-orders-pending-postponed",
          serviceCustomPath: "/extranet-providers/provider-orders-pending-postponed",
          title: "Pedidos aplazados, pendientes de confirmar",
        },
      },
      ...orderEditView("orders-postponed/pending", "ExtranetProviderOrdersPendingPostponed"),

      {
        path: "orders-postponed/confirmed",
        name: "ExtranetProviderOrdersPostponed",
        component: () =>
          import("@/modules/decoralife-eecs-provider/views/ExtranetProviderOrdersPostponed"),
        meta: {
          auth: [5],
          serviceName: "extranet-provider-orders-postponed",
          title: "Pedidos aplazados, confirmados",
        },
      },
      ...orderEditView("orders-postponed/confirmed", "ExtranetProviderOrdersPostponed"),

      {
        path: "shipments",
        name: "ExtranetProviderShipments",
        component: () =>
          import("@/modules/decoralife-eecs-provider/views/ExtranetProviderShipments"),
        meta: {
          auth: [5],
          serviceName: "extranet-provider-shipments",
          title: "Envíos",
        },
      },
      {
        path: "shipments/:id",
        name: "ExtranetProviderShipmentsEdit",
        component: crudUpdateMd,
        meta: {
          auth: [5],
          serviceName: "extranet-provider-shipments",
          title: "Detalle de envío",
        },
      },
    ],
  },
];
