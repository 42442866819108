import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  {
    path: "settings",
    redirect: { name: "eecsVendors" },
  },
  {
    path: "settings/vendors",
    name: "eecsVendors",
    component: () => import("@/modules/decoralife-eecs-admin/views/VendorsCrud"),
    meta: {
      auth: [3],
      serviceName: "vendors",
      title: "Vendors",
    },
  },
  {
    path: "settings/vendors/:id",
    name: "eecsVendorsEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "vendors",
      title: "Vendor",
    },
  },

  {
    path: "settings/filters",
    name: "eecsFiltersPoToOrder",
    component: () => import("@/modules/decoralife-eecs-admin/views/PoFilters"),
    meta: {
      auth: [3],
      serviceName: "po-to-order-filters",
      title: "Filtros auto. de PO a Pedido",
    },
  },
  {
    path: "settings/filters/:id",
    name: "eecsFiltersPoToOrderEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "po-to-order-filters",
      title: "Filtro auto. de PO a Pedido",
    },
  },

  {
    path: "settings/warehouses",
    name: "eecsWarehouses",
    component: () => import("@/modules/decoralife-eecs-admin/views/WarehousesCrud"),
    meta: {
      auth: [3],
      serviceName: "warehouses",
      title: "Almacenes propios",
    },
  },
  {
    path: "settings/warehouses/:id",
    name: "eecsWarehousesEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "warehouses",
      title: "Almacén",
    },
  },

  {
    path: "settings/destination-warehouses",
    name: "eecsDestinationWarehouses",
    component: () => import("@/modules/decoralife-eecs-admin/views/DestinationWarehousesCrud"),
    meta: {
      auth: [3],
      serviceName: "amazon-warehouses",
      title: "Almacenes de clientes",
    },
  },
  {
    path: "settings/destination-warehouses/:id",
    name: "eecsDestinationWarehousesEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "amazon-warehouses",
      title: "Almacén de cliente",
    },
  },

  {
    path: "settings/order-stages",
    name: "eecsOrderStages",
    component: () => import("@/modules/decoralife-eecs-admin/views/OrderStagesCrud"),
    meta: {
      auth: [3],
      serviceName: "provider-order-stages",
      title: "Estados de pedidos proveedor",
    },
  },
  {
    path: "settings/order-stages/:id",
    name: "eecsOrderStagesEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "provider-order-stages",
      title: "Estado de pedido proveedor",
    },
  },

  /*
      {
        path: "po-stages",
        name: "eecsPoStages",
        component: () => import("@/modules/decoralife-eecs-admin/views/PoStagesCrud"),
        meta: {
          auth: [3],
          serviceName: "po-stages",
          title: "Lista de estados de PO",
        },
      },
      {
        path: "po-stages/:id",
        name: "eecsPoStagesEdit",
        component: crudUpdateMd,
        meta: {
          auth: [3],
          serviceName: "po-stages",
          title: "Estado de PO",
        },
      },
 */

  {
    path: "settings/po-categories",
    name: "eecsPoCategories",
    component: () => import("@/modules/decoralife-eecs-admin/views/PoCategoriesCrud"),
    meta: {
      auth: [3],
      serviceName: "po-categories",
      title: "Categorías de PO de cliente",
    },
  },
  {
    path: "settings/po-categories/:id",
    name: "eecsPoCategoriesEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "po-categories",
      title: "Categoría de PO de cliente",
    },
  },

  {
    path: "settings/mailing-tempaltes",
    name: "eecsMailingTemplates",
    component: () => import("@/modules/decoralife-eecs-admin/views/MailingTemplateCrud"),
    meta: {
      auth: [3],
      serviceName: "mailing-templates",
      title: "Plantillas de email",
    },
  },
  {
    path: "settings/mailing-tempaltes/:id",
    name: "eecsMailingTemplatesEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "mailing-templates",
      title: "Plantilla de email",
    },
  },

  {
    path: "settings/imports",
    name: "eecsImports",
    component: () => import("@/modules/decoralife-eecs-admin/views/ImportsCrud"),
    meta: {
      auth: [3],
      serviceName: "imports",
      title: "Historial de importaciones",
    },
  },
  {
    path: "settings/users/imports/:id",
    name: "eecsImportsEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "imports",
      title: "Importación programada",
    },
  },

  {
    path: "settings/global-variables",
    name: "globalVariables",
    component: () => import("@/modules/decoralife-eecs-admin/views/GlobalVariables"),
    meta: {
      auth: [3],
      serviceName: "global-config",
      title: "Gestión de variables globales",
    },
  },
];
