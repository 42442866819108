import apiClient from "@/services/ApiClient";

const name = "pos-generate-order";

export default {
  name,
  getProvidersPendingPosData() {
    return apiClient.get("provider-orders/pending-pos-data");
  },
  getPosPendingOrder(queryParams) {
    return apiClient.get(`provider-orders/generate${queryParams}`);
  },
  updatePendingOrderPoLines(payload) {
    return apiClient.put("po-lines/bulk-update", payload);
  },
  generateOrders(payload) {
    return apiClient.post("provider-orders/generate", payload);
  },
};
