import authService from "@/services/AuthService";
import dashboard from "@/services/DashboardService";
import teleMktServices from "@/modules/decoralife-tele-mkt/services/index";
import eecsServices from "@/modules/decoralife-eecs-admin/services/index";

const services = [authService, dashboard, ...teleMktServices, ...eecsServices];

export default {
  get: name => services.find(s => s.name === name),
};
