import apiClient from "@/services/ApiClient";

const name = "auth";

export default {
  name,
  login(credentials) {
    return apiClient.post("/auth/login", credentials);
  },
};
