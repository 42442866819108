import { crudMdActions } from "@/services/crud-md-actions";
import apiClient from "@/services/ApiClient";

const name = "provider-order-lines";

export default {
  name,
  ...crudMdActions(name),
  cancelOrderLines(payload) {
    return apiClient.post(`/provider-orders/${this.$route.params.id}/cancel-lines`, payload);
  },
};
