import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  {
    path: "providers/list",
    name: "eecsProvidersList",
    component: () => import("@/modules/decoralife-eecs-admin/views/ProvidersListCrud"),
    meta: {
      auth: [3],
      serviceName: "providers",
      title: "Proveedores",
    },
  },
  {
    path: "providers/list/:id",
    name: "eecsProvidersListEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "providers",
      title: "Proveedor",
    },
  },

  {
    path: "providers/contacts",
    name: "eecsProviderContacts",
    component: () => import("@/modules/decoralife-eecs-admin/views/ProviderContactsCrud"),
    meta: {
      auth: [3],
      serviceName: "provider-contacts",
      title: "Contactos de proveedor",
    },
  },
  {
    path: "providers/contacts/:id",
    name: "eecsProviderContactsEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "provider-contacts",
      title: "Contacto de proveedor",
    },
  },
];
