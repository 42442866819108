import Vue from "vue";
import Vuex from "vuex";
import AuthService from "@/services/AuthService";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import router from "@/router";
import apiClient, { apiClientBlob } from "@/services/ApiClient";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

//Store Modules
//import CancelRequests from "@/store/modules/CancelRequests"

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    currentService: null,
    currentTitle: null,
    login_redirect: null,
    userSettings: {
      darkMode: false,
      fontSize: 1,
    },
    user: {
      loggedIn: null,
    },
  };
};

export default new Vuex.Store({
  modules: {
    //*CancelRequests,
  },
  plugins: [
    createPersistedState({
      key: "state",
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  state: getDefaultState(),
  mutations: {
    UPDATE_TITLE(state, event) {
      state.currentTitle = event;
    },
    TOGGLE_DARK_MODE(state, event) {
      state.userSettings.darkMode = event;
    },
    UPDATE_FONT_SIZE(state, event) {
      state.userSettings.fontsize = event;
    },
    SET_USER_DATA(state, userData) {
      const { token } = userData;
      delete userData.token;

      const unix_timestamp = parseInt(userData.expiry);
      const expiryDate = new Date(unix_timestamp * 1000);
      const dateNow = new Date();
      const timeToExpiry = expiryDate - dateNow;
      const daysToExpiry = Math.round((timeToExpiry / (1000 * 60 * 60) / 24) * 2) / 2;

      Cookies.set("auth_token", token, {
        expires: daysToExpiry,
        sameSite: "Lax",
        secure: true,
      });

      apiClient.defaults.headers.common["Http-Authorization"] = `Bearer ${token}`;
      apiClientBlob.defaults.headers.common["Http-Authorization"] = `Bearer ${token}`;

      state.user = userData;
      state.user.loggedIn = true;
      createPersistedState();
    },
    UPDATE_CURRENT_SERVICE(state, newService) {
      state.currentService = newService;
    },
    CLEAR_USER_DATA(state) {
      Cookies.remove("auth_token");
      state.user = { loggedIn: null };
      state.currentService = null;
      createPersistedState();
    },
  },
  actions: {
    updateTitle({ commit }, title) {
      commit("UPDATE_TITLE", title);
    },
    toggleDarkMode({ commit }, event) {
      commit("TOGGLE_DARK_MODE", event);
    },
    updateFontSize({ commit }, fontsize) {
      commit("UPDATE_FONT_SIZE", fontsize);
    },
    updateCurrentService({ commit }, newService) {
      commit("UPDATE_CURRENT_SERVICE", newService);
    },
    login({ commit }, payload) {
      if (payload.providerLogin) {
        return AuthService.providerLogin(payload.credentials).then(({ data }) => {
          commit("SET_USER_DATA", data);
          const { login_redirect } = this.state;
          return login_redirect || router.resolve({ name: "dashboard" }).route.fullPath;
        });
      } else {
        return AuthService.login(payload.credentials).then(({ data }) => {
          commit("SET_USER_DATA", data);
          const { login_redirect } = this.state;
          return login_redirect || router.resolve({ name: "dashboard" }).route.fullPath;
        });
      }
    },
    logout({ commit }) {
      commit("CLEAR_USER_DATA");
      location.reload();
    },
  },
});
