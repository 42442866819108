import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/Vuetify";

import "material-design-icons-iconfont/dist/material-design-icons.css";

/**** PLUGINS ****/

// Vuetify Confirm dialog
import VuetifyConfirm from "./plugins/VuetifyConfirm";

// Vuetify JSON Schema form
import VJsf from "./plugins/VJsf";

// vue-notification
import Notifications from "./plugins/VueNotification";

// Moment JS
import Moment from "./plugins/Moment";

// vue2-google-maps
// see docs: https://github.com/xkjyeah/vue-google-maps
// import VueGoogleMaps from "./plugins/VueGoogleMaps"

// VueCurrency
// import VueCurrency from "./plugins/VueCurrency"

//Global mixin
import GlobalMixin from "@/mixins/global";

import apiClient, { apiClientBlob } from "@/services/ApiClient";
import Cookies from "js-cookie";

Vue.config.productionTip = false;
Vue.mixin(GlobalMixin);

const token = Cookies.get("auth_token");
apiClient.defaults.headers.common["Http-Authorization"] = `Bearer ${token}`;
apiClientBlob.defaults.headers.common["Http-Authorization"] = `Bearer ${token}`;

new Vue({
  router,
  store,
  Notifications,
  vuetify,
  VuetifyConfirm,
  VJsf,
  Moment,
  render: h => h(App),
}).$mount("#app");
