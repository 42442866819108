import { crudMdActions } from "@/services/crud-md-actions";
import apiClient from "@/services/ApiClient";

const name = "clients";

export default {
  name,
  ...crudMdActions(name),
  getClientData(clientId) {
    return apiClient.get(`/clients/${clientId}/profile`);
  },
  getClientBrandsAdminData(clientId) {
    return apiClient.get(`/clients/${clientId}/brands`);
  },
  getClientBrandsOperatorData(clientId) {
    return apiClient.get(`/clients/${clientId}/operator-brands`);
  },
  updateClientBrandsData(payload) {
    return apiClient.put("/clientsbrands", payload);
  },
  getCalls(clientId) {
    return apiClient.get(`/clients/${clientId}/calls`);
  },
  getOperators() {
    return apiClient.get(`/users/select-operator-items`);
  },
  getBrands() {
    return apiClient.get(`/brands/select-items`);
  },
  getPriorities() {
    return apiClient.get("/priorities/newCall");
  },
  getCallResults() {
    return apiClient.get("/call-results/newCall");
  },
  getQualifications() {
    return apiClient.get("/qualifications/select-items");
  },
  updateClientComments(clientId, payload) {
    return apiClient.put(`/clients/${clientId}`, payload);
  },
  addNewComment(clientId, payload) {
    return apiClient.post(`/clients/${clientId}/comments`, payload);
  },
  getCallData(callId) {
    return apiClient.get(`/calls/${callId}`);
  },
  updateCall(payload, callId) {
    return apiClient.put(`/calls/${callId}`, payload);
  },
  updateComment(payload, clientId, commentId) {
    return apiClient.put(`/clients/${clientId}/comments/${commentId}`, payload);
  },
  newCall(payload) {
    return apiClient.post("/calls/plannedCall", payload);
  },
  closeNewCall(payload) {
    return apiClient.post("/calls/closedCall", payload);
  },
  deleteCall(callId) {
    return apiClient.delete(`/calls/${callId}`);
  },
  deleteComment(clientId, commentId) {
    return apiClient.delete(`/clients/${clientId}/comments/${commentId}`);
  },
  bulkAssignOperator(payload) {
    return apiClient.put("/clientsbrands", payload);
  },
  getOrders(params) {
    return apiClient.get(`/orders?${params}`);
  },
  getOrderData(id) {
    return apiClient.get(`/orders/${id}`);
  },
};
