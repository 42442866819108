import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  {
    path: "products",
    redirect: { name: "eecsProducts" },
  },
  {
    path: "products/list",
    name: "eecsProducts",
    component: () => import("@/modules/decoralife-eecs-admin/views/Products"),
    meta: {
      auth: [3],
      serviceName: "products",
      title: "Productos",
    },
  },
  {
    path: "products/list/:id",
    name: "eecsProductsEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "products",
      title: "Producto",
    },
  },

  {
    path: "products/sizes",
    name: "eecsProductSizes",
    component: () => import("@/modules/decoralife-eecs-admin/views/ProductSizes"),
    meta: {
      auth: [3],
      serviceName: "product-sizes",
      title: "Tallas de producto",
    },
  },
  {
    path: "products/sizes/:id",
    name: "eecsProductSizesEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "product-sizes",
      title: "Talla de producto",
    },
  },

  {
    path: "products/states",
    name: "eecsProductStates",
    component: () => import("@/modules/decoralife-eecs-admin/views/ProductStates"),
    meta: {
      auth: [3],
      serviceName: "product-states",
      title: "Estados de producto",
    },
  },
  {
    path: "products/states/:id",
    name: "eecsProductStatesEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "product-states",
      title: "Estado de producto",
    },
  },

  {
    path: "products/families",
    name: "eecsProductFamilies",
    component: () => import("@/modules/decoralife-eecs-admin/views/ProductFamilies"),
    meta: {
      auth: [3],
      serviceName: "product-families",
      title: "Familias de producto",
    },
  },
  {
    path: "products/families/:id",
    name: "eecsProductFamiliesEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "product-families",
      title: "Familia de producto",
    },
  },
];
