import { crudMdActions } from "@/services/crud-md-actions";
import apiClient from "@/services/ApiClient";

const name = "pos";

export default {
  name,
  ...crudMdActions(name),
  sendPosToWarehouse(payload) {
    return apiClient.post(`/pos/send-to-warehouse`, payload);
  },
};
