export default [
  {
    path: "pos/list",
    name: "eecsPos",
    component: () => import("@/modules/decoralife-eecs-admin/views/PosCrud"),
    meta: {
      auth: [3],
      title: "PO de clientes",
      serviceName: "pos",
    },
  },
  {
    path: "pos/list/:id",
    name: "eecsPosEdit",
    component: () => import("@/modules/decoralife-eecs-admin/views/PosPoLinesCrud"),
    meta: {
      auth: [3],
      serviceName: "po-lines",
      title: "Detalle de PO",
    },
  },

  {
    path: "pos/pending-order",
    name: "eecsPosPendingOrder",
    component: () => import("@/modules/decoralife-eecs-admin/views/PosPendingOrderCrud"),
    meta: {
      auth: [3],
      title: "PO de clientes pendientes de pedido",
      serviceName: "pos-pending-order",
    },
  },
  {
    path: "pos/pending-order/:id",
    name: "eecsPosPendingOrderEdit",
    component: () => import("@/modules/decoralife-eecs-admin/views/PosPoLinesCrud"),
    meta: {
      auth: [3],
      serviceName: "po-lines",
      title: "Detalle de PO",
    },
  },

  {
    path: "pos/generate-orders",
    name: "eecsPosGenerateOrders",
    component: () => import("@/modules/decoralife-eecs-admin/views/PosGenerateOrders"),
    meta: {
      auth: [3],
      title: "Procesado de POs a Pedidos",
      serviceName: "pos-generate-order",
      hideNavDrawer: true,
    },
  },
];
