<template>
  <view-base @cancel="cancel">
    <div class="fill-height d-flex flex-column align-stretch prd-maxwidth-100">
      <div class="flex-shrink-1 flex-grow-0 prd-maxwidth-100">
        <theHeading>
          <template #tools-left>
            <slot name="title-icons"></slot>
          </template>
          <template #tools-right>
            <v-btn color="error" small @click="cancel" text> Cerrar </v-btn>
            <v-btn
              v-if="currentComponent === 'form-vjsf'"
              type="submit"
              :form="formId"
              color="success"
              class="px-6 mx-4"
              :loading="submitLoading"
              small
              text
            >
              Guardar
            </v-btn>
            <v-btn
              v-if="currentComponent === 'form-vjsf'"
              @click.stop="submitForm(true)"
              :form="formId"
              color="success"
              class="px-6"
              :loading="submitLoading"
              small
              depressed
            >
              Guardar y cerrar
            </v-btn>
          </template>
        </theHeading>
      </div>

      <v-col
        v-if="isLoading"
        class="d-flex align-center justify-center fill-height prd-maxwidth-100"
      >
        <v-progress-circular class="my-12" :size="50" :color="loadingColor" indeterminate />
      </v-col>

      <div class="flex-shrink-0 flex-grow-1 d-flex flex-column prd-maxwidth-100">
        <v-tabs
          v-model="currentTab"
          v-if="updateActions.length > 1"
          class="my-4 flex-grow-0 flex-shrink-1 prd-maxwidth-100"
          show-arrows
          next-icon="keyboard_arrow_right"
          prev-icon="keyboard_arrow_left"
          height="40"
          grow
        >
          <v-tab v-for="tab in updateActions" :key="tab.title" @click="handleTabClick" class="px-6">
            {{ tab.title }}
          </v-tab>
        </v-tabs>

        <div class="flex-grow-1 flex-shrink-0 d-flex prd-maxwidth-100">
          <keep-alive>
            <component
              :key="currentTab"
              :is="currentComponent"
              v-bind="customProps"
              @submitForm="submitForm($event)"
            />
          </keep-alive>
        </div>
      </div>
    </div>
  </view-base>
</template>

<script>
import serviceFactory from "@/modules/decoralife-eecs-provider/services/ServiceFactory";

export default {
  components: {
    FormVjsf: () => import("@/components/form-vjsf"),
    ViewBase: () => import("@/layouts/intranet-tabs/ViewBase"),
    theHeading: () => import("@/components/TheToolsBar"),
  },
  props: {},
  data: () => ({
    formData: {
      id: null,
      model: {},
      schema: {},
      filterParams: {},
    },
    updateActions: [],
    availableComponents: {
      form: "form-vjsf",
    },
    isLoading: null,
    loadingColor: null,
    itemTitle: null,
    backdoor: 0,
    itemId: null,
    submitLoading: null,
    prevRoute: null,
  }),
  methods: {
    async submitForm(close = false) {
      try {
        this.submitLoading = true;
        await this.$store.state.currentService.updateItem(this.formData.id, this.formData.model);
      } catch {
        this.$notifyError();
      } finally {
        this.submitLoading = false;
        if (close) this.closeItem();
      }
    },
    async getTabsData() {
      this.isLoading = true;

      try {
        const service = this.$store.state.currentService;
        const response = await service.getUpdateItemForm(this.itemId);

        this.updateActions = response.data.tabs;
        if (response.data.title) {
          this.itemTitle += " | " + response.data.title;
          await this.$store.dispatch("updateTitle", this.itemTitle);
        }

        this.formData = this.updateActions.find(action => action.type === "form").form_data;

        this.isLoading = false;
      } catch {
        this.$notifyError();
      }
    },
    cancel() {
      this.resetContent();
      this.closeItem();
    },
    closeItem() {
      if (this.prevRoute && this.prevRoute.name) {
        this.$router.push({ path: this.prevRoute.fullPath });
      } else {
        const [parentName] = this.$route.name.split("-");
        this.$router.replace({ name: parentName });
      }
    },
    resetContent() {
      this.contentKey += 1;
    },
    handleTabClick() {
      this.backdoor++;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  beforeMount() {
    this.loadingColor = ["success", "error", "warning", "info"][Math.floor(Math.random() * 4)];
    this.itemId = this.$route.params["id"];
    this.itemTitle = "#" + this.itemId;
    this.$store.dispatch("updateTitle", this.itemTitle);
  },
  mounted() {
    this.getTabsData();
  },
  computed: {
    currentTab: {
      get() {
        const queryTab = parseInt(this.$route.query.tab) - 1 || 0;
        if (this.updateActions) {
          return this.updateActions[queryTab] ? queryTab : 0;
        } else {
          return 0;
        }
      },
      set(newValue) {
        this.$router.push({
          query: { tab: parseInt(newValue) + 1 },
        });
        return newValue;
      },
    },
    formId() {
      return this.$store.state.currentService.name + "_" + this.itemId;
    },
    currentComponent() {
      //Return component Type name
      if (this.updateActions.length > 0) {
        const componentType = this.currentTab !== null && this.updateActions[this.currentTab].type;
        return componentType && this.availableComponents[componentType];
      } else {
        return null;
      }
    },
    customProps() {
      let prop = null;

      const currentAction = this.updateActions[this.currentTab];

      if (currentAction && currentAction.type === "form") {
        prop = {
          key: this.contentKey,
          formId: this.formId,
          formData: this.formData,
          style: "width: 100%",
        };
      } else if (currentAction && currentAction.type === "crud") {
        const { serviceName } = currentAction;
        const { params } = currentAction;

        prop = {
          service: serviceFactory.get(serviceName),
          crudParams: params,
        };

        const storeCrudMetaData = this.$store.state.crudMetaData;

        if (
          storeCrudMetaData.childEditCustomFunction &&
          storeCrudMetaData.childEditCustomFunction[currentAction.title]
        ) {
          // if custom Edit is defined in View and matches with tab Name
          prop.customEdit = storeCrudMetaData.childEditCustomFunction[currentAction.title];
        }
      }

      return prop;
    },
  },
};
</script>

<style lang="scss" scoped></style>
