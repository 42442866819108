import queryString from "query-string";

export default {
  data() {
    return {
      $globalRules: {
        required: value => {
          if (Array.isArray(value) && value.length === 0) return false;
          return !(value === "" || value === null || value === undefined) || "Campo obligatorio";
        },
        number: value => {
          return value ? !isNaN(value) || "Nº." : true;
        },
        hour: value => {
          return value ? (value < 24 && value >= 0) || "24 h." : true;
        },
        minute: value => {
          return value ? (value < 60 && value >= 0) || "24 h." : true;
        },
        // Dates
        notPastDate: value => {
          const date = this.$moment(value, ["DD/MM/YYYY", "YYYY-MM-DD"]);
          const notPast = this.$moment(date).isAfter(this.$moment().subtract(1, "days"));
          return notPast || "Fecha pasada";
        },
      },
    };
  },
  methods: {
    $notifySuccess(title = "¡Hecho!", text = "Operación realizada correctmente.") {
      this.$notify({
        type: "success",
        title: title,
        text: text,
      });
    },
    $notifyError(
      title = "Error",
      text = "Ha ocurrido algún problema en el proceso, vuelve a intentarlo o contacta con el administrador."
    ) {
      this.$notify({
        type: "error",
        title: title,
        text: text,
      });
    },
    $userIsSuperAdmin() {
      return (
        this.$store.state.user.roles && this.$store.state.user.roles.map(r => r.id).includes(6)
      );
    },
    $userIsOperatorAdmin() {
      return (
        (this.$store.state.user.roles && this.$store.state.user.roles.map(r => r.id).includes(1)) ||
        this.$userIsSuperAdmin()
      );
    },
    $userIsOperator() {
      return (
        (this.$store.state.user.roles && this.$store.state.user.roles.map(r => r.id).includes(2)) ||
        this.$userIsSuperAdmin()
      );
    },
    $userIsTeleMkt() {
      return this.$userIsOperator() || this.$userIsOperatorAdmin();
    },
    $userIsEecsDirector() {
      return (
        (this.$store.state.user.roles && this.$store.state.user.roles.map(r => r.id).includes(3)) ||
        this.$userIsSuperAdmin()
      );
    },
    $userIsEecs() {
      return this.$userIsEecsDirector() || this.$userIsSuperAdmin();
    },
    $userIsIn(users) {
      if (this.$userIsSuperAdmin()) return true;
      if (this.$store.state.user.roles) {
        return users.some(currentValue =>
          this.$store.state.user.roles.map(r => r.id).includes(currentValue)
        );
      }
      return false;
    },
    $joinParams(params) {
      return Object.keys(params)
        .map(function (k) {
          let paramValue = params[k];
          let isArrEmpty = Array.isArray(paramValue) && paramValue.length === 0;
          if (paramValue === null || paramValue === undefined || isArrEmpty) return null;

          if (typeof paramValue === "object" && !Array.isArray(params[k])) {
            paramValue = JSON.stringify(params[k]);
          }

          if (Array.isArray(paramValue)) {
            paramValue = paramValue.join();
          }

          return k + "=" + paramValue;
        })
        .filter(e => e !== null)
        .join("&");
    },

    $encodeCriteria(criteria) {
      if (!criteria.length) return undefined;

      const stringifiedCriteria = [];

      for (let criterion of criteria) {
        stringifiedCriteria.push(queryString.stringify(criterion, { arrayFormat: "index" }));
      }
      return queryString.stringify(stringifiedCriteria, {
        arrayFormat: "index",
      });
    },

    $formatNumberToCurrency(value) {
      // Ex:  get 2.5
      // console.log("NTC: getNumber", value)
      //const newValue = value.toLocaleString("es-ES")

      return new Intl.NumberFormat("es-ES", {
        maximumFractionDigits: 4,
        style: "currency",
        currency: "EUR",
      }).format(value);

      // Ex: return 2,5
    },

    $getAmazonLink(asin) {
      if (!asin) return "missing ASIN";
      return `https://amazon.es/dp/${asin}`;
    },

    /*
    $formatCurrencyToNumber(value) {
      // Ex:  get 2,50
      // console.log("CTN: getCurrency", value)

      const splitValue = value.split(",")
      const units = splitValue[0].replace(".", "")
      const decimals = splitValue[1] || 0
      const newValue = parseFloat(units + "." + decimals)

      // console.log("CTN: setNumber", newValue)
      return newValue
      // Ex:  return 2.5
    },
    $downloadPdf(service) {
      service
        .then(response => {
          const link = document.createElement("a")
          link.href =
            "data:application/octet-stream;base64," + response.data.binary
          link.download = response.data.filename
          link.dispatchEvent(new MouseEvent("click"))
          link.remove()
        })
        .catch(() => {
          this.$notifyError(undefined, "Error en la descàrrega del PDF.")
        })
    },
     */
  },
};
