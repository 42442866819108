import apiClient from "@/services/ApiClient";

const name = "stats";

export default {
  name,
  getOperators() {
    return apiClient.get(`/users/select-operator-items`);
  },
  getBrands() {
    return apiClient.get(`/brands/select-items`);
  },
  getProductivityStats(params) {
    return apiClient.get(`/stats/productivity?${params}`);
  },
  getClosedCallsStats(params) {
    return apiClient.get(`/stats/closed-calls?${params}`);
  },
};
