import apiClient from "@/services/ApiClient";

const name = "global-config";

export default {
  name,
  getGlobalVariables() {
    return apiClient.get("/global-config/variables");
  },
  putGlobalVariables(payload) {
    return apiClient.put(`/global-config/variables`, payload);
  },
};
