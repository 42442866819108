import destinationWarehousesService from "@/modules/decoralife-eecs-admin/services/destinationWarehousesService";
import directorUsersService from "@/modules/decoralife-eecs-admin/services/eecsDirectorUsersService";
import globalVariablesService from "@/modules/decoralife-eecs-admin/services/globalVariablesService";
import importsService from "@/modules/decoralife-eecs-admin/services/importsService";
import mailingTemplatesServiceService from "@/modules/decoralife-eecs-admin/services/mailingTemplatesService";
import orderStagesService from "@/modules/decoralife-eecs-admin/services/orderStagesService";
import posService from "@/modules/decoralife-eecs-admin/services/posService";
import posGenerateOrderService from "@/modules/decoralife-eecs-admin/services/posGenerateOrderService";
import posPendingOrderService from "@/modules/decoralife-eecs-admin/services/posPendingOrderService";
import posUpdateService from "@/modules/decoralife-eecs-admin/services/poLinesService";
import poFiltersService from "@/modules/decoralife-eecs-admin/services/poFiltersService";
import poStagesService from "@/modules/decoralife-eecs-admin/services/poStagesService";
import poCategoriesService from "@/modules/decoralife-eecs-admin/services/poCategoriesService";
import productsService from "@/modules/decoralife-eecs-admin/services/productsService";
import productSizesService from "@/modules/decoralife-eecs-admin/services/productSizesService";
import productStatesService from "@/modules/decoralife-eecs-admin/services/productStatesService";
import productFamiliesService from "@/modules/decoralife-eecs-admin/services/productFamiliesService";
import providersService from "@/modules/decoralife-eecs-admin/services/providersService";
import providerOrdersService from "@/modules/decoralife-eecs-admin/services/providerOrdersService";
import providerOrderUpdateService from "@/modules/decoralife-eecs-admin/services/providerOrderLinesService";
import providerShipmentsService from "@/modules/decoralife-eecs-admin/services/providerShipmentsService";
import providerContactsService from "@/modules/decoralife-eecs-admin/services/providerContactsService";
import providerUsersService from "@/modules/decoralife-eecs-admin/services/providerUsersService";
import vendorsService from "@/modules/decoralife-eecs-admin/services/vendorsService";
import warehousesService from "@/modules/decoralife-eecs-admin/services/warehousesService";

export default [
  destinationWarehousesService,
  directorUsersService,
  globalVariablesService,
  importsService,
  mailingTemplatesServiceService,
  orderStagesService,
  posService,
  posGenerateOrderService,
  posPendingOrderService,
  posUpdateService,
  poFiltersService,
  poStagesService,
  poCategoriesService,
  productsService,
  productSizesService,
  productStatesService,
  productFamiliesService,
  providersService,
  providerContactsService,
  providerOrdersService,
  providerOrderUpdateService,
  providerShipmentsService,
  providerUsersService,
  vendorsService,
  warehousesService,
];
