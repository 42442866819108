/*
 **** ROLES ****
 *
 * 1: Dir. telemarketing - telemarketing_director (demo_user: demo@intuix.cat)
 * 2: Operadora - operator (demo_user: operadora@intuix.cat)
 * 3: Director PO's - po_director (demo_user: directorpo@intuix.cat)
 * 4: Almacén - warehouse (demo_user: almacen@intuix.cat)
 * 5: Proveedor - eecs-provider (demo_user: proveedor@intuix.cat)
 * 6: Super admin - admin (demo_user: admin@intuix.cat)
 *
 ** END ROLES **
 */

import store from "@/store/index";
import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";

import Intranet from "@/layouts/intranet-tabs";

import teleMktRoutes from "@/modules/decoralife-tele-mkt/router";
import eecsRoutes from "@/modules/decoralife-eecs-admin/router";
import providerRoutes from "@/modules/decoralife-eecs-provider/router";

Vue.use(VueRouter);

let routes;

if (window.location.hostname.split(".")[0] === "eecs") {
  routes = providerRoutes;
} else {
  const baseRoutes = [
    {
      path: "/login",
      component: () => import("@/layouts/intranet-empty/Index.vue"),
      children: [
        {
          path: "",
          name: "login",
          component: () => import("@/views/UserLogin.vue"),
          meta: {
            auth: "guest",
            title: "Acceso de usuario",
          },
        },
      ],
    },

    {
      path: "/",
      component: Intranet,
      children: [
        {
          path: "",
          component: () => import("@/views/Dashboard.vue"),
          name: "dashboard",
          meta: {
            auth: [1, 2, 3, 4, 6],
            title: "Panel de control",
            hideBreadcrumb: true,
            serviceName: "dashboard",
          },
        },
      ],
    },
  ];
  const notFoundRoute = [
    {
      path: "*",
      component: () => import("@/views/errors/404.vue"),
    },
  ];

  routes = baseRoutes.concat(teleMktRoutes, eecsRoutes, notFoundRoute);
}

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === undefined) {
    // 404 paths
    next();
  } else {
    let allowedRoles = to.matched.map(record => record.meta.auth).filter(record => record);
    if (allowedRoles.length > 1) {
      // Only one auth can be used per path
      return null;
    }

    const loggedIn = Cookies.get("auth_token") && store.state.user.loggedIn;

    if (loggedIn) {
      if (allowedRoles[0] === "guest") {
        next(from.path);
      } else {
        const userRoles = store.state.user.roles.map(role => role.id);
        const authorized = allowedRoles
          .flat()
          .filter(allowedRole => userRoles.includes(allowedRole));
        if (authorized.length) {
          next();
        } else {
          from.name === "login"
            ? next({ name: "dashboard", query: { forbidden: 1 } })
            : next({ path: from.fullPath, query: { forbidden: 1 } });
        }
      }
    } else {
      if (store.state.user.loggedIn) store.commit("CLEAR_USER_DATA");

      if (!allowedRoles.length || allowedRoles[0] === "guest") {
        next();
      } else {
        store.state.login_redirect = to.fullPath;
        next({ name: "login" });
      }
    }
  }
});

export default router;
