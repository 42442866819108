<template>
  <v-app id="eecs-provider">
    <notifications position="bottom left" width="400px" classes="prd-notification" />

    <!-- APP Nav -->
    <TheNav :is-provider-layout="true" :navDrawer="navDrawer" @toggleMenu="toggleMenu($event)" />

    <!-- APP toolbar -->
    <TheTopBar :navDrawer="navDrawer" @toggleMenu="toggleMenu($event)" />

    <!-- APP Content -->
    <v-main :class="{ 'prd-main-full-width': !navDrawer }">
      <v-container :fluid="fluid" class="fill-height d-flex flex-column align-stretch">
        <div class="flex-grow-1 flex-shrink-0 d-flex prd-maxwidth-100">
          <router-view></router-view>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ServiceFactory from "@/modules/decoralife-eecs-provider/services/ServiceFactory";

export default {
  components: {
    TheNav: () => import("@/modules/decoralife-eecs-provider/layouts/intranet/components/Nav"),
    TheTopBar: () => import("@/modules/decoralife-eecs-provider/components/ProviderTopBar.vue"),
  },
  props: {
    fluid: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    navDrawer: null,
    notification: {
      position: "bottom left",
      duration: 6000,
    },
  }),
  methods: {
    toggleMenu(value) {
      this.navDrawer = value;
    },
  },
  created() {
    //dark mode true/false
    this.$vuetify.theme.dark = this.$store.state.userSettings.darkMode;

    //font resize
    const { fontSize } = this.$store.state.userSettings;
    const [html] = document.getElementsByTagName("html");
    if (fontSize) {
      html.style.fontSize = fontSize + "em";
    }
  },
  updated() {
    if (this.$route.query.forbidden) {
      const newQuery = { ...this.$route.query };
      delete newQuery.forbidden;
      this.$router.replace({ query: newQuery });

      this.$notifyError(
        "No tienes permisos",
        "No tienes suficientes permisos para acceder a esta página. Si crees que esto es un error, por favor, consúltalo con el responsable del sistema."
      );
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        // UPDATE title
        let title = "";
        const paramId = this.$route.params.id;
        if (paramId) title += `#${paramId} | `;
        title += to.meta?.title;
        if (title) {
          document.title = title;
          this.$store.dispatch("updateTitle", title);
        }

        to.meta.hideNavDrawer || this.$vuetify.breakpoint.smAndDown
          ? (this.navDrawer = false)
          : (this.navDrawer = true);

        // UPDATE Store currentService
        const currentServiceName = this.$store.state.currentService?.name;
        const serviceName = to.meta?.serviceName;
        const newService = serviceName ? ServiceFactory.get(serviceName) : {};

        if (!from || currentServiceName !== newService.name) {
          this.$store.dispatch("updateCurrentService", newService);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.eecs-provider {
  h1,
  .text-h1,
  h2,
  .text-h2,
  h3,
  .text-h3,
  h4,
  .text-h4,
  h5,
  .text-h5,
  h6,
  .text-h6 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: bold;
  }

  .prd-notification {
    padding: 10px;
    margin: 10px;
    font-size: 0.9em;
    color: white;
    background: #669999 !important;
    border-left: 5px solid #3a6b6b !important;
    &.warn {
      background: #ff9351 !important;
      border-left-color: #b2490a !important;
    }
    &.error {
      background: #ff4344 !important;
      border-left-color: #bb2e31 !important;
    }
    &.success {
      background: #78c644 !important;
      border-left-color: #427520 !important;
    }
  }

  .v-tab {
    border-bottom: 1px black solid;
    &--active {
      color: white !important;
      /*TODO - #00bba7 as primary color as variable, not hardcoded*/
      background-color: #669999;
    }
  }

  .prd-flex-1 {
    display: flex;
    flex: 1;
    max-width: 100%;
  }
  .prd-maxwidth-100 {
    max-width: 100%;
  }
}
</style>
