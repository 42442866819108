import { crudMdActions } from "@/services/crud-md-actions";
import apiClient from "@/services/ApiClient";

const name = "new-clients";

export default {
  name,
  ...crudMdActions(name),
  getClientData(clientId) {
    return apiClient.get(`/clients/${clientId}/profile`);
  },
  getClientBrandsAdminData(clientId) {
    return apiClient.get(`/clients/${clientId}/brands`);
  },
  updateClientBrandsData(payload) {
    return apiClient.put("/clientsbrands", payload);
  },
  updateClientComments(clientId, payload) {
    return apiClient.put(`/clients/${clientId}`, payload);
  },
  getOperators() {
    return apiClient.get(`/users/select-operator-items`);
  },
  getQualifications() {
    return apiClient.get("/qualifications/select-items");
  },
  getPriorities() {
    return apiClient.get("/priorities/newCall");
  },
};
