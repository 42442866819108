import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  {
    path: "users",
    redirect: { name: "eecsProviderUsers" },
  },
  {
    path: "users/provider",
    name: "eecsProviderUsers",
    component: () => import("@/modules/decoralife-eecs-admin/views/ProviderUsersCrud"),
    meta: {
      auth: [3],
      serviceName: "eecs-provider-users",
      title: "Usuarios de proveedor",
    },
  },
  {
    path: "users/provider/:id",
    name: "eecsProviderUsersEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "eecs-provider-users",
      title: "Usuario de proveedor",
    },
  },

  {
    path: "users/director",
    name: "eecsDirectorUsers",
    component: () => import("@/modules/decoralife-eecs-admin/views/DirectorUsersCrud"),
    meta: {
      auth: [3],
      serviceName: "dir-eecs-users",
      title: "Directores EECS",
    },
  },
  {
    path: "users/director/:id",
    name: "eecsDirectorUsersEdit",
    component: crudUpdateMd,
    meta: {
      auth: [3],
      serviceName: "dir-eecs-users",
      title: "Director EECS",
    },
  },
];
