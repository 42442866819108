import Intranet from "@/layouts/intranet-tabs";
import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  {
    path: "/tele-mkt/clients",
    component: Intranet,
    children: [
      /*
      {
        path: "list",
        component: () => import("@/views/crud/ClientsCrud"),
        name: "clients",
        meta: {
          auth: [1, 2],
          title: "Clientes",
          serviceName: "clients",
        },
      },
      */
      {
        path: "list",
        component: () => import("@/modules/decoralife-tele-mkt/views/ClientsBrandsCrud"),
        name: "clients",
        meta: {
          auth: [1, 2],
          title: "Clientes",
          serviceName: "clientsbrands",
        },
      },
      {
        path: "list/:id",
        component: () => import("@/modules/decoralife-tele-mkt/views/ClientsCrudEdit"),
        name: "clientsEdit",
        meta: {
          auth: [1, 2],
          title: "Cliente",
          serviceName: "clients",
        },
      },
      {
        path: "new-clients",
        name: "newClients",
        component: () => import("@/modules/decoralife-tele-mkt/views/NewClientsCrud"),
        meta: {
          auth: [1],
          serviceName: "new-clients",
          title: "Clientes pendientes de clasificar",
        },
      },
      {
        path: "new-clients/:id",
        name: "newClientsEdit",
        component: () => import("@/modules/decoralife-tele-mkt/views/NewClientsCrudEdit"),
        meta: {
          auth: [1],
          serviceName: "new-clients",
          title: "Nuevo cliente",
        },
      },
    ],
  },

  {
    path: "/tele-mkt/planned-calls",
    component: Intranet,
    children: [
      {
        path: "",
        component: () => import("@/modules/decoralife-tele-mkt/views/CallsPlannedCrud"),
        name: "planned-calls",
        meta: {
          auth: [1, 2],
          title: "Llamadas pendientes",
          serviceName: "planned-calls",
        },
      },
    ],
  },

  {
    path: "/tele-mkt/calls-history",
    component: Intranet,
    children: [
      {
        path: "",
        component: () => import("@/modules/decoralife-tele-mkt/views/CallsHistoryCrud"),
        name: "calls-history",
        meta: {
          auth: [1, 2],
          title: "Histórico de llamadas",
          serviceName: "closed-calls",
        },
      },
    ],
  },

  {
    path: "/tele-mkt/stats",
    component: Intranet,
    children: [
      {
        path: "productivity",
        name: "stats-productivity",
        component: () => import("@/modules/decoralife-tele-mkt/views/stats/tele-mkt/Productivity"),
        meta: {
          auth: [1],
          title: "Eficiencia en la gestión del tiempo",
          serviceName: "stats",
        },
      },
      {
        path: "calls",
        name: "stats-calls",
        component: () => import("@/modules/decoralife-tele-mkt/views/stats/tele-mkt/ClosedCalls"),
        meta: {
          auth: [1],
          title: "Estadística de llamadas realizadas",
          serviceName: "stats",
        },
      },
    ],
  },

  {
    path: "/tele-mkt/settings",
    component: Intranet,
    redirect: { name: "workOrderTypes" },
    meta: {
      title: "Configuraciones",
    },
    children: [
      {
        path: "brands",
        name: "brands",
        component: () => import("@/modules/decoralife-tele-mkt/views/BrandsCrud"),
        meta: {
          auth: [1],
          serviceName: "brands",
          title: "Marcas",
        },
      },
      {
        path: "brands/:id",
        name: "brandsEdit",
        component: crudUpdateMd,
        meta: {
          auth: [1],
          serviceName: "brands",
          title: "Marca",
        },
      },

      {
        path: "qualifications",
        name: "qualifications",
        component: () => import("@/modules/decoralife-tele-mkt/views/QualificationsCrud"),
        meta: {
          auth: [1],
          serviceName: "qualifications",
          title: "Calificaciones de cliente",
        },
      },
      {
        path: "qualifications/:id",
        name: "qualificationsEdit",
        component: crudUpdateMd,
        meta: {
          auth: [1],
          serviceName: "qualifications",
          title: "Calificación",
        },
      },

      {
        path: "priorities",
        name: "priorities",
        component: () => import("@/modules/decoralife-tele-mkt/views/PrioritiesCrud"),
        meta: {
          auth: [1],
          serviceName: "priorities",
          title: "Prioridades de llamada",
        },
      },
      {
        path: "priorities/:id",
        name: "prioritiesEdit",
        component: crudUpdateMd,
        meta: {
          auth: [1],
          serviceName: "priorities",
          title: "Prioridad de llamada",
        },
      },

      {
        path: "call-results",
        name: "callResults",
        component: () => import("@/modules/decoralife-tele-mkt/views/CallResultsCrud"),
        meta: {
          auth: [1],
          serviceName: "call-results",
          title: "Resultados de llamada",
        },
      },
      {
        path: "call-results/:id",
        name: "callResultsEdit",
        component: crudUpdateMd,
        meta: {
          auth: [1],
          serviceName: "call-results",
          title: "Resultado de llamada",
        },
      },
    ],
  },

  {
    path: "/tele-mkt/users",
    component: Intranet,
    redirect: { name: "operatorUsers" },
    meta: {
      title: "Usuarios",
    },
    children: [
      {
        path: "operators",
        name: "teleMktUsers",
        component: () => import("@/modules/decoralife-tele-mkt/views/TeleMktCrud"),
        meta: {
          auth: [1],
          serviceName: "telemkt-users",
          title: "Operadoras",
        },
      },
      {
        path: "operators/:id",
        name: "teleMktUsersEdit",
        component: crudUpdateMd,
        meta: {
          auth: [1],
          serviceName: "telemkt-users",
          title: "Operadora",
        },
      },

      {
        path: "director",
        name: "dirTeleMktUsers",
        component: () => import("@/modules/decoralife-tele-mkt/views/DirTeleMktUsersCrud"),
        meta: {
          auth: [1],
          serviceName: "dir-telemkt-users",
          title: "Dir. telemarketing",
        },
      },
      {
        path: "director/:id",
        name: "dirTeleMktUsersEdit",
        component: crudUpdateMd,
        meta: {
          auth: [1],
          serviceName: "dir-telemkt-users",
          title: "Dir. telemarketing",
        },
      },
    ],
  },
];
