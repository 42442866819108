import { crudMdActions } from "@/services/crud-md-actions";
import apiClient from "@/services/ApiClient";

const name = "po-lines";

export default {
  name,
  ...crudMdActions(name),
  getPoHeaderData(poId) {
    return apiClient.get(`/pos/resume/${poId}`);
  },
  getPoCategories() {
    return apiClient.get("/po-categories/select-items");
  },
  setPoCategory(poId, payload) {
    return apiClient.put(`/pos/${poId}/category`, payload);
  },
};
