import { crudMdActions } from "@/services/crud-md-actions";
import apiClient from "@/services/ApiClient";

const name = "extranet-provider-orders-pending-postponed";

export default {
  name,
  ...crudMdActions(name, "extranet-providers/provider-orders-pending-postponed"),
  confirmOrdersByProvider(payload) {
    return apiClient.put("/extranet-providers/provider-orders-pending/bulk-confirm", payload);
  },
};
